/* *{
  margin: 0px;
  padding: 0px;
}

html {
  font-size: 62.5%;
}

.privacy-policy-main-container {
  background-color: rgb(234, 250, 247);
}

.privacy-policy-main-sub-container {
  padding: 8rem;
}

span.privacy-policy-main-heading-title {
  font-size: xx-large;
  font-weight: bold;
}

.privacy-policy-intro {
  display: flex;
  flex-flow: column;
  padding-top: 5rem;
}

span.privacy-intro-title {
  font-size: x-large;
  font-weight: bold;
}

span.privacy-intro-sub-title {
  font-size: medium;
}

.privacy-policy-service-title {
  margin-top: 3rem;
}

span.privacy-policy-service-title-name {
  font-size: medium;
}

.privacy-policy-end-title {
  margin-top: 2rem;
}

span.privacy-policy-end-title {
  font-size: medium;
}

li.privacy-policy-list-item {
  font-size: medium;
} */


* {
  margin: 0px;
  padding: 0px;
}

html {
  font-size: 62.5%;
}

.privacy-policy-main-container {
  background-color: rgb(234, 250, 247);
}

.privacy-policy-main-sub-container {
  padding: 2rem 6rem;
}

span.privacy-policy-main-heading-title {
  font-size: xx-large;
  font-weight: bold;
}

.privacy-policy-intro {
  display: flex;
  flex-flow: column;
  padding-top: 5rem;
}

span.privacy-intro-title {
  font-size: x-large;
  font-weight: bold;
}

span.privacy-intro-sub-title {
  font-size: medium;
}

.privacy-policy-service-title {
  margin-top: 3rem;
}

span.privacy-policy-service-title-name {
  font-size: medium;
}

.privacy-policy-end-title {
  margin-top: 2rem;
}

span.privacy-policy-end-title {
  font-size: medium;
}

li.privacy-policy-list-item {
  font-size: medium;
}

/* Responsive styles for screens with max-width 600px */
@media (max-width: 600px) {
  .privacy-policy-main-sub-container {
    padding: 2rem 4rem;
  }

  span.privacy-policy-main-heading-title {
    font-size: 2rem;
  }

  .privacy-policy-intro {
    padding-top: 3rem;
  }

  span.privacy-intro-title {
    font-size: 1.8rem;
  }

  span.privacy-intro-sub-title, 
  li.privacy-policy-list-item, 
  span.privacy-policy-service-title-name, 
  span.privacy-policy-end-title {
    font-size: 1.4rem;
  }

  .privacy-policy-service-title {
    margin-top: 2rem;
  }

  .privacy-policy-end-title {
    margin-top: 1.5rem;
  }
}
