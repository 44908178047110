.coupon-sub-container {
  padding: 5rem;
}
  
span.coupon-header-title {
  font-size: x-large;
  font-weight: bold;
  color: rgb(32, 61, 90);
}

span.item-coupon-defination {
  font-size: medium;
}

.coupon-create-process-group{
  margin-top: 5rem;
}

span.coupon-question-header {
  font-size: medium;
  font-weight: bold;
  color: rgb(32, 61, 90);
}

li.coupon-list-process {
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: medium;
}

img.coupon-first-img-class {
  width: 100%;
}

img.coupon-second-img-class {
  width: 100%;
}

/* Responsive styles for screens with max-width 600px */
@media (max-width: 600px) {
  .coupon-sub-container {
    padding: 2rem;
  }

  span.coupon-header-title {
    font-size: large;
  }

  span.item-coupon-defination {
    font-size: small;
  }

  .coupon-create-process-group {
    margin-top: 3rem;
  }

  span.coupon-question-header {
    font-size: small;
  }

  li.coupon-list-process {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: small;
  }

  img.coupon-first-img-class,
  img.coupon-second-img-class {
    width: 100%;
  }
}
