*{
  margin: 0px;
  padding: 0px;
}

html {
  font-size: 62.5%;
}

.home-main-container{
  background-color: white;
  overflow: hidden;
}

.home-container-level-first {
  padding: 3rem;
  text-align: -webkit-center;
  position: relative; 
  overflow: hidden; 
  background-image: url('../../Assets/Images/backgroundimage.jpg'); 
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat; 
  color: var(--text-color, #212529); 
  --o-cc-bg: var(--overlay-bg, #FFFFFF); 
}

.home-container-level-first::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--o-cc-bg); 
  z-index: 1; 
  opacity: 0.7;
}

.home-container-level-first > * {
  position: relative;
  z-index: 2; 
}

span.home-container-first-title {
  font-size: xxx-large;
  font-weight: bold;
}

.home-container-second-title {
  margin-top: 1rem;
}

span.home-container-second-title-content, 
.home-container-third-title-content  {
  font-size: medium;
}

.home-container-third-title {
  margin-top: 1rem;
}

.desktop-dashboard-image {
  margin-top: 2rem;
}

img.home-desktop-img-class {
  height: 90vh;
  width: 70%;
  transform: scale(0);
  animation: growToOriginalSize 1.5s ease-out forwards; 
}

@keyframes growToOriginalSize {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1); 
  }
}

/* Mobile styles for screens with a max-width of 600px */
@media screen and (max-width: 600px) {
  img.home-desktop-img-class {
    height: 30vh;
    width: 85%;
    transform: scale(0);
    animation: growToOriginalSize 1.5s ease-out forwards; 
  }

  .bubble img {
    width: 30px;
    height: 30px;
  }

  @keyframes bubbleUp {
    0% {
      transform: translateY(0) scale(0.7); 
      opacity: 0.5;
    }
    50% {
      transform: translateY(-40vh) scale(0.9);
      opacity: 0.7;
    }
    100% {
      transform: translateY(-80vh) scale(0.7);
      opacity: 0.5;
    }
  }

  .bubble-1 {
    left: 10%;
    animation-duration: 7s; 
  }

  .bubble-2 {
    left: 25%;
    animation-duration: 9s;
  }

  .bubble-3 {
    left: 45%;
    animation-duration: 8s;
  }

  .bubble-4 {
    left: 55%;
    animation-duration: 10s;
  }

  .bubble-5 {
    left: 75%;
    animation-duration: 6s;
  } 
}

.bubble {
  position: absolute;
  bottom: 0; 
  animation: bubbleUp 10s infinite;
  opacity: 0.8;
}

.bubble img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

@keyframes bubbleUp {
  0% {
    transform: translateY(0) scale(0.8);
    opacity: 0.6;
  }
  50% {
    transform: translateY(-50vh) scale(1); 
    opacity: 0.8;
  }
  100% {
    transform: translateY(-100vh) scale(0.8); 
    opacity: 0.6;
  }
}

@keyframes bubbleSide {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(-20px);
  }
}

.bubble-1 {
  left: 10%;
  animation-duration: 8s;
}

.bubble-2 {
  left: 30%;
  animation-duration: 12s;
}

.bubble-3 {
  left: 50%;
  animation-duration: 10s;
}

.bubble-4 {
  left: 70%;
  animation-duration: 14s;
}

.bubble-5 {
  left: 90%;
  animation-duration: 8s;
} 

/* Container for the rotating and moving animation */

/* The rotating shape */
/* .animation-shape.shape-triangle {
  position: absolute;
  width: 100px; 
  height: 100px; 
  overflow: hidden;
}

.animation-shape.shape-triangle .animation--rotating {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transform-origin: center;
  animation: rotatingShape 5s linear infinite;
}

.animation-shape.shape-triangle .animation--rotating::before,
.animation-shape.shape-triangle .animation--rotating::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-top-right-radius: 30%;
  width: 15px;
  height: 15px;
  background-color: orange; 
  opacity: 0.1;
}

.animation-shape.shape-triangle .animation--rotating::before {
  transform: rotate(-60deg) skewX(-30deg) scaleY(0.866);
}

.animation-shape.shape-triangle .animation--rotating::after {
  transform: rotate(60deg) skewX(30deg) scaleY(0.866);
}

@keyframes rotatingShape {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveClockwiseAnticlockwise {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(100px, 0px) rotate(90deg);
  }
  50% {
    transform: translate(100px, 100px) rotate(180deg);
  }
  75% {
    transform: translate(0px, 100px) rotate(270deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes moveAnticlockwise {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(-100px, 0px) rotate(-90deg);
  }
  50% {
    transform: translate(-100px, -100px) rotate(-180deg);
  }
  75% {
    transform: translate(0px, -100px) rotate(-270deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(-360deg);
  }
}

.animation--rotating-diagonal {
  animation: rotating-diagonal 15s linear infinite alternate;
} */


/* home features */
.home-features-container {
  width: 85%;
  margin: auto;
}

.home-key-features {
  display: flex;
  justify-content: center;
  margin-bottom: 10rem;
  margin-top: 10rem;
}

span.home-key-feature-heading {
  color: rgb(32, 61, 90);
  font-size: xx-large;
  font-weight: bold;
}

.home-key-features-menu-grp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home-key-features-first-row,
.home-key-features-second-row,
.home-key-features-third-row,
.home-key-features-fourth-row,
.home-key-features-fifth-row,
.home-key-features-fifth-row,
.home-key-features-sixth-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8rem;
}

.feature-item {
  text-align: center;
  width: 30%;
  margin: 0 1%;
}

.feature-item img {
  max-width: 8rem; 
  margin-bottom: 1.5rem;
}

.feature-title {
  font-weight: bold;
  margin-bottom: 1rem; 
  display: block;
  font-size: 1.6rem; 
}

.feature-paragraph {
  font-size: 1.4rem; 
  line-height: 1.6;
}

.home-key-feature-btn-grp {
  justify-content: center;
  display: flex;
}

button.home-more-feature-btn {
  background-color: rgb(32, 61, 90);
  color: white;
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid rgb(32, 61, 90);
  font-size: large;
  font-weight: bold;
}

/* home features for mobile screen */
@media (max-width: 600px) {
  .home-features-container {
    width: 95%; 
  }

  .home-key-features {
    margin-bottom: 5rem; 
  }

  span.home-key-feature-heading {
    font-size: 2rem; 
    text-align: center; 
  }

  .home-key-features-menu-grp {
    flex-direction: column; 
    align-items: center;
  }

  .home-key-features-first-row,
  .home-key-features-second-row,
  .home-key-features-third-row,
  .home-key-features-fourth-row,
  .home-key-features-fifth-row,
  .home-key-features-sixth-row {
    flex-direction: column; 
    margin-bottom: 4rem;
  }

  .feature-item {
    width: 90%;
    margin: 2rem; 
  }

  .feature-item img {
    max-width: 6rem;
  }

  .feature-title {
    font-size: 1.4rem;
  }

  .feature-paragraph {
    font-size: 1.2rem; 
  }

  .home-key-feature-btn-grp {
    margin-top: 2rem;
  }

  button.home-more-feature-btn {
    width: 100%; 
    font-size: 1.4rem; 
    padding: 1.5rem;
  }
}

/* Multiple business CSS */
.home-multiple-business-group {
  width: 85%;
  margin: auto;
  margin-top: 5rem;
}

.home-multiple-business-title-div {
  display: flex;
  justify-content: center;
}

span.home-multiple-business-title {
  color: rgb(32, 61, 90);
  font-size: xx-large;
  font-weight: bold;
}

.home-multiple-business-symbol-grp {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}

.business-symbol-div {
  background-color: #0f4075;
  padding: 0.5rem 1.2rem;
  border-radius: 1rem;
  text-align: center;
  width: 15%;
}

span.business-symbol-name {
  font-weight: bold;
  font-size: medium;
  color: #ff0;
  margin: 0px;
}

hr.business-upper-line {
  border-style: dashed;
}

/* Responsive for multiple business CSS  */
@media (max-width: 600px) {
  .home-multiple-business-group {
    width: 95%; 
    margin-top: 3rem;
  }

  span.home-multiple-business-title {
    font-size: 1.8rem;
    text-align: center; 
  }

  .home-multiple-business-symbol-grp {
    flex-direction: column; 
    align-items: center;
    margin-top: 2rem; 
  }

  .business-symbol-div {
    width: 80%; 
    margin-bottom: 2rem; 
  }

  span.business-symbol-name {
    font-size: 1.4rem;
  }

  hr.business-upper-line {
    margin-top: 2rem; 
  }
}


/*  Middle container CSS*/
.home-middle-container-title-group {
  background-image: url('../../Assets/Images/middlebgimage.jpg'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top: 1px solid #ebeef0;
  margin-top: 10rem;
  color: white;
  height: 30rem;
  background-attachment: fixed;
}

.home-middle-container-parent-div {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 8rem;
}

.middle-container-left-grp,
.middle-container-right-grp {
  margin-bottom: 0;
}

.middle-container-left-subtitle-first,
.middle-container-left-subtitle-second,
.middle-container-right-subtitle-first,
.middle-container-right-subtitle-second {
  display: block; 
}

.middle-container-left-subtitle-first,
.middle-container-left-subtitle-second {
  font-size: x-large; 
  font-weight: bold;
  margin: 0;
}

.middle-container-right-subtitle-first,
.middle-container-right-subtitle-second {
  font-size: medium; 
  max-width: 75%;
}

.middle-vl {
  border-left: 3px solid white;
  height: 10rem; 
}

a.on-premises-class {
  text-decoration: none;
}

@media (max-width: 600px) {
  .home-middle-container-title-group {
    height: 40rem;
  }

  .middle-vl {
    border-left: 3px solid white;
    height: 10rem; 
  }

  .home-middle-container-parent-div {
    flex-direction: column; 
    gap: 1rem;
  }

  .middle-vl {
    border-left: none;
    border-top: 3px solid white;
    height: 0;
    width: 100%;
  }

  .middle-container-left-subtitle-first,
  .middle-container-left-subtitle-second
  {
    font-size: x-large;
  }

  .middle-container-right-subtitle-first,
  .middle-container-right-subtitle-second{
    font-size: x-large;;
    margin-left: 2rem;
  }  
}

/* second level CSS */
.home-container-level-second {
  display: flex;
  gap: 2rem;
  padding: 6rem;
  justify-content: space-around;
  margin-top: 10rem;
}

.home-container-level-second-left {
  flex: 1; 
}

img.home-mob-img-class {
  height: 60vh;
  width: 25vw;
  transition: transform 0.5s ease-out;
}

.home-container-level-second-right {
  flex: 1; 
}

span.home-level-second-heading-title {
  color: rgb(32, 61, 90);
  font-size: xx-large;
  font-weight: bold;
}

.home-level-second-title-name-grp {
  margin-top: 2rem;
}

span.home-level-second-title-name {
  font-size: medium;
}

/* Media Query for max-width 600px */
@media screen and (max-width: 600px) {
  .home-container-level-second {
    flex-direction: column; 
    padding: 2rem;
    align-items: center; 
  }

  .home-container-level-second-right{
    margin-top: 4rem;
  }

  .home-container-level-second-left,
  .home-container-level-second-right {
    width: 100%;
  }

  img.home-mob-img-class {
    width: 60vw; 
    height: auto; 
    margin-top: 3rem; 
    transition: transform 0.5s ease-out;
  }

  .home-level-second-heading-title-grp {
    order: 1; 
    text-align: center; 
  }

  .home-container-level-second-left {
    order: 2; 
    display: flex;
    justify-content: center; 
  }
}

/* Third level CSS */
.home-container-level-third {
  display: flex;
  gap: 10rem;
  padding: 4rem;
  margin-top: 10rem;
}

.level-third-left {
  flex: 1;
}

.level-third-right {
  flex: 1;
}

.third-level-img-class {
  width: 100%; 
  height: auto; 
}

span.level-third-left-content {
  color: rgb(32, 61, 90);
  font-size: xx-large;
  font-weight: bold;
}

/* Media Query for max-width 600px */
@media screen and (max-width: 600px) {
  .home-container-level-third {
    flex-direction: column; 
    padding: 2rem; 
    align-items: center; 
  }

  .level-third-left {
    order: 1; 
    text-align: center; 
  }

  .level-third-right {
    order: 2; 
    display: flex;
    justify-content: center; 
    width: 100%; 
  }

  .third-level-img-class {
    width: 80vw; 
    height: auto;
    margin-top: 1rem; 
  }
}

/* Fourth level CSS */
.home-container-level-fourth {
  display: flex;
  gap: 10rem;
  padding: 4rem;
  margin-top: 10rem;
}

.level-fourth-left {
  flex: 1;
}

img.fourth-level-img-class {
  height: 60vh;
  width: 45vw;
}

span.level-fourth-left-content {
  color: rgb(32, 61, 90);
  font-size: xx-large;
  font-weight: bold;
}

span.level-fourth-left-sub-content {
  font-size: medium;
}

.level-fourth-content-summary {
  padding-right: 1rem;
}

/* Media Query for max-width 600px */
@media screen and (max-width: 600px) {
  .home-container-level-fourth {
    flex-direction: column; 
    padding: 1rem; 
    align-items: center; 
  }

  .level-fourth-left {
    order: 1;
    text-align: center; 
    margin-top: 3rem;
  }

  .level-fourth-right {
    order: 2; 
    display: flex;
    justify-content: center; 
    width: 100%;
    transform: translateX(0); 
    opacity: 1; 
  }

  img.fourth-level-img-class {
    width: 80vw; 
    height: auto;
    margin-top: 1rem; 
  }
}

/* fifth level CSS */
.level-fifth-inner-container {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  padding-top: 10rem;
}

.level-fifth-inner-div {
  border: 2px solid rgb(32, 61, 90);
  height: auto;
  border-radius: 0.5rem;
}

ul.list-group.list-group-flush {
  list-style-type: none;
}

.level-fifth-inner-header {
  background-color: rgb(32, 61, 90);
  color: white;
  line-height: 3rem;
}

span.fifth-inner-header-title {
  display: flex;
  justify-content: center;
  font-size: larger;
}

.fifth-inner-start-div {
  border-bottom: 2px solid rgb(32, 61, 90);
  line-height: 4rem;
}

.fifth-card-group-list {
  border-bottom: 2px solid rgb(32, 61, 90);
}

.fifth-card-group-list {
  border-bottom: 2px solid rgb(32, 61, 90);
  line-height: 3rem;
}

li.list-group-item {
  border-bottom: 1px solid grey;
  text-align: center;
  font-size: medium;
}

li.list-group-last-item {
  text-align: center;
  font-size: medium;
}

.fifth-card-footer {
  text-align: center;
}

.fifth-card-footer {
  text-align: center;
  line-height: 3rem;
  background-color: rgb(32, 61, 90);
  color: white;
  padding: 1rem;
}

a.fifth-card-button {
  border: 1px solid #3078BC;
  border-radius: 1rem;
  background-color: #3078BC;
  text-decoration: none;
  padding: 8px 12px;
  color: white;
}

span.fifth-inner-start-span {
  display: flex;
  justify-content: center;
  font-size: medium;
}

p.fifth-card-footer-paragraph {
  font-size: medium;
}

@media screen and (max-width: 600px) {
  .level-fifth-inner-container {
    margin-top: 5rem;
    padding: 2rem;
  }

  .fifth-card-footer-paragraph {
    font-size: medium;
  }
}

span.home-container-new-title {
  font-size: xx-large;
  font-weight: bold;
}

span.home-new-container-second-title {
  font-size: medium;
}
