.footer-main {
  background-color: #1e3a55;
  color: #fff;
  padding: 2rem 0;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.5rem 1rem;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin-bottom: 1.5rem;
}

.footer-section h4 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
  color: #8abbdc;
  font-size: larger;
}

.footer-section ul li:hover{
  color: white;
  text-decoration: underline;
}

.footer-section ul li i {
  margin-right: 0.5rem;
}

.footer-section a {
  color: #8abbdc;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
  color: white;
}

.map-container {
  margin-bottom: 1rem;
}

.footer-bottom a {
  color: #8abbdc;
  text-decoration: none;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    text-align: center;
    margin-bottom: 2rem;
  }

  .footer-section ul li {
    margin-bottom: 0.5rem;
    color: #8abbdc;
    font-size: larger;
  }
}

.footer-bottom {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #162c3d;
  color: #8abbdc;
  text-align: center;
}

span.footer-copyright-title {
  margin-left: 3rem;
}

span.footer-second-title {
  margin-right: 3rem;
}

footer {
  margin-top: auto;
}

a.footer-company-link {
  cursor: pointer;
}

li.footer-section-list-item {
  cursor: pointer;
}

li.footer-section-list-item-company {
  text-decoration: none;
}

.footer-section-list-item-privacy-policy{
  cursor: pointer;
}

@media (max-width: 600px) {
  span.footer-copyright-title {
    margin-left: 0.4rem;
  }

  span.footer-second-title {
    margin-right: 0.4rem;
  }
}
