* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sale-main-container {
  padding: 4rem;
  flex: 1;
}

span.sale-header-title {
  color: rgb(32, 61, 90);
  font-size: xx-large;
  font-weight: bold;
}

p.sale-first-paragraph,
.sale-second-paragraph {
  font-size: medium;
}

img.sale-img-class {
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

span.sale-class-second-header-title {
  color: rgb(32, 61, 90);
  font-size: x-large;
  font-weight: bold;
}

/* Responsive styles for screens with a max-width of 600px */
@media (max-width: 600px) {
  .sale-main-container {
    padding: 2rem;
  }

  span.sale-header-title {
    font-size: large;
  }

  p.sale-first-paragraph,
  .sale-second-paragraph {
    font-size: small;
  }

  img.sale-img-class {
    width: 100%; 
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); 
  }

  span.sale-class-second-header-title {
    font-size: medium;
  }
}
