*{
  margin: 0px;
  padding: 0px;
}

html {
  font-size: 62.5%;
}

/* for header level first css*/
.header-level-first-main {
  overflow: hidden; 
  white-space: nowrap;
  background-color: #112941;
  color: #FFFFFF;
  text-align: center;
  line-height: 2rem;
}

.header-level-first-title {
  display: block;
  font-size: medium;
  animation: moveText 5s linear infinite;
}

@keyframes moveText {
  0% {
    transform: translateX(40%); 
  }
  100% {
    transform: translateX(-50%);
  }
}

/* for header level second css*/
.header-level-second-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: black;
  padding: 10px;
  flex-wrap: wrap; 
}

.second-level-header-left {
  flex: 1;
  text-align: left;
  margin-bottom: 10px; 
}

.second-level-header-right {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  flex: 1;
  text-align: right;
}

.search-container {
  position: relative;
  display: inline-block;
}

.search-container input[type="text"] {
  width: 100%;
  box-sizing: border-box;
  padding: 8px 12px;
  border-radius: 1rem;
  border: 1px solid #3078BC;
}

.search-container i.fa-search {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; 
}

a.btn.btn-outline-secondary.header-sign-link {
  border: 1px solid;
  padding: 8px 12px;
  border-radius: 1rem;
  color: #3078BC;
  border-color: #3078BC;
}

a.btn.btn-outline-secondary.header-sign-link:hover{
  color: #FFFFFF;
  background-color: #3078BC;
  border-color: #3078BC;
}

@media (max-width: 600px) {
  .header-level-second-main {
    flex-direction: column;
  }

  .second-level-header-left,
  .second-level-header-right {
    text-align: center;
    margin-bottom: 10px;
  }

  .second-level-header-right {
    justify-content: center;
  }
}

/* for header level third css*/
.full-screen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  flex-direction: column;
}

.navbar {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.navbar-nav {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.nav-item {
  margin: 1rem 0;
}

.nav-link {
  font-size: 2rem;
  font-weight: bold;
  color: grey;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
}

.nav-link:hover {
  color: #163756;
}

.close-button {
  font-size: 2rem;
  color: grey;
  cursor: pointer;
  align-self: flex-end; 
  margin-bottom: 1rem;
}

a.nav-link.header-attribute-name {
  font-size: medium;
  display: block;
}

.nav-link.active {
  font-weight: bold;
  border-bottom: 1px solid #112941;
}


/* Mobile styles for screens with a max-width of 600px */
@media screen and (max-width: 600px) {
  .full-screen-menu {
    padding: 1rem;
  }

  .nav-link {
    font-size: 1.5rem;
  }

  .close-button {
    font-size: 2.5rem;
  }

  .nav-link.active {
    font-weight: bold;
    border-bottom: none;
  }
}
