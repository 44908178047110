* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; 
}

.contact-header {
  text-align: center;
  background-color: #4a4a4a;
  color: #fff;
  padding: 2rem 0;
}

.contact-header h1 {
  font-size: 3.6rem; 
  margin: 0;
}

.contact-header p {
  margin: 1rem 0 2rem;
  font-size: 1.8rem;
}

.contact-form-container {
  display: flex;
  gap: 5rem; 
  justify-content: space-between;
  padding: 2rem;
}

.contact-form {
  flex: 1;
  min-width: 30rem;
  margin-top: 1rem;
}

.contact-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 1rem; 
  margin-bottom: 1.5rem; 
  border: 1px solid #ccc;
  border-radius: 0.4rem;
}

.contact-form textarea {
  resize: vertical;
}

.contact-form button {
  background-color: #1a2f3c;
  color: white;
  padding: 1rem 2rem; 
  border: none;
  border-radius: 0.5rem; 
  cursor: pointer;
}

.contact-info {
  flex: 1;
  min-width: 30rem; 
  display: flex;
  flex-direction: column;
}

.contact-info h2 {
  font-size: 2.4rem; 
  margin-bottom: 1rem; 
}

.contact-info p {
  font-size: medium; 
  margin: 0.5rem 0;
}

.contact-info i {
  margin-right: 1rem;
}

span.contact-subtitle {
  font-size: medium;
}

p.contact-message {
  color: red;
  margin-top: 0.5rem;
  font-size: medium;
}

@media (max-width: 768px) {
  .contact-form-container {
    flex-direction: column;
    gap: 2rem; 
  }
}

@media (max-width: 600px) {
  .contact-header h1 {
    font-size: 2.8rem; 
  }

  .contact-header p {
    font-size: medium;
  }

  .contact-form-container {
    padding: 1rem; 
  }

  .contact-form,
  .contact-info {
    min-width: 100%; 
  }

  .contact-form input,
  .contact-form textarea {
    padding: 0.8rem;
  }

  .contact-form button {
    padding: 0.8rem 1.6rem; 
  }

  .contact-info h2 {
    font-size: 2rem; 
  }

  .contact-info p {
    font-size: 1.4rem; 
  }
}
