.about-main-container {
  overflow: hidden;
}

.about-header {
  text-align: left;
  color: black;
  padding: 20px 0;
}

span.about-header-title {
  font-size: xx-large;
  font-weight: bold;
  color: white;
  margin-left: 2rem;
}

.about-sub-level-first {
  display: flex;
  justify-content: space-around;
  padding: 5rem;
  gap: 0.5rem;
}

img.about-mob-img {
  height: 100%;
  width: 100%;
}

.about-sub-level-first-left {
  padding-top: 10rem;
}

span.about-sub-level-first-right-title {
  font-size: xxx-large;
  font-weight: bold;
}

span.about-sub-level-first-right-title-content {
  font-size: larger;
}

.about-sub-level-second {
  text-align: center;
}

span.about-sub-level-second-title {
  font-size: xxx-large;
  font-weight: bold;
}

.about-sub-level-third {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 5rem;
  padding: 15rem;
}

img.ceo-img-class {
  height: 35vh;
  border-radius: 50%;
}

span.ceo-message-content-title {
  font-size: x-large;
  font-weight: bold;
}

.ceo-message-sub-content-grp {
  margin-top: 1rem;
  font-size: larger;
}

.about-sub-level-first-right {
  padding-top: 8rem;
  padding-left: 6rem;
}

.ceo-message-grp {
  padding-top: 2rem;
}

.about-sub-level-first {
  display: flex;
  justify-content: space-around;
  padding: 5rem;
  gap: 0.5rem;
}

.about-sub-level-first-left {
  padding-top: 10rem;
}

img.about-mob-img {
  height: 100%;
  width: 100%;
}

.about-sub-level-first-right {
  padding-top: 8rem;
  padding-left: 6rem;
}

span.about-sub-level-first-right-title {
  font-size: xxx-large;
  font-weight: bold;
}

span.about-sub-level-first-right-title-content {
  font-size: medium;
}

span.ceo-message-sub-content {
  font-size: medium;
}

/* Media Query for max-width 600px */
@media screen and (max-width: 600px) {
  .about-main-container {
    overflow: hidden;
  }
  
  .about-sub-level-first {
    flex-direction: column; 
    padding: 1rem; 
    align-items: center; 
    text-align: center; 
  }

  .about-sub-level-first-right {
    order: 1; 
    padding-top: 2rem;
    padding-left: 0; 
  }

  .about-sub-level-first-left {
    order: 2;
    padding-top: 1rem;
    padding: 2rem 0;
    display: flex;
    justify-content: center; 
    width: 100%;
  }

  img.about-mob-img {
    height: auto; 
    width: 80%; 
  }

  .about-sub-level-second{
    margin-top: 4rem;
  }

  .about-sub-level-second{
    margin-top: 1rem;
  }

  .about-sub-level-third {
    /* display: flex; */
    justify-content: center;
    margin-top: 2rem;
    padding: 6rem;
    flex-direction: column; 
  }

  .ceo-img-class{
    order:2;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .ceo-message-grp{
    order: 1;
    display: flex;
    justify-content: center; 
    width: 100%;
    flex-direction: column; 
  }

  .ceo-message-content-title{
    font-size: larger;
  }

  .ceo-message-sub-content-grp {
    margin-top: 1rem;
    font-size: medium;
  }
}
