.unit-sub-container {
  padding: 5rem;
}

span.unit-header-title {
  font-size: x-large;
  font-weight: bold;
  color: rgb(32, 61, 90);
}

span.item-unit-defination {
  font-size: medium;
}

.unit-list-grp {
  font-size: x-large;
  font-weight: bold;
  color: rgb(32, 61, 90);
  margin-top: 4rem;
}

li.unit-menu-name {
  font-size: medium;
}

.unit-create-process-group {
  margin-top: 5rem;
}

span.unit-question-header {
  font-size: medium;
  font-weight: bold;
  color: rgb(32, 61, 90);
}

li.unit-list-process {
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: medium;
}

img.unit-first-img-class {
  width: 100%;
}

img.unit-second-img-class {
  border: 1px solid black;
  width: 50%;
}

/* Responsive styles for screens with max-width 600px */
@media (max-width: 600px) {
  .unit-sub-container {
    padding: 2rem;
  }

  span.unit-header-title {
    font-size: large;
  }

  span.item-unit-defination {
    font-size: small;
  }

  .unit-list-grp {
    font-size: large;
    margin-top: 2rem;
  }

  li.unit-menu-name {
    font-size: small;
  }

  .unit-create-process-group {
    margin-top: 3rem;
  }

  span.unit-question-header {
    font-size: small;
  }

  li.unit-list-process {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: small;
  }

  img.unit-first-img-class {
    width: 100%;
  }

  img.unit-second-img-class {
    width: 80%;
  }
}
