* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.inventory-main-container {
  padding: 4rem;
  flex: 1;
}

span.inventory-header-title {
  color: rgb(32, 61, 90);
  font-size: xx-large;
  font-weight: bold;
}

p.inventory-paragraph-first,
.inventory-paragraph-second,
.inventory-paragraph-third{
  font-size: medium;
}

img.inventory-img-class {
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

span.inventory-class-third-header-title {
  color: rgb(32, 61, 90);
  font-size: x-large;
  font-weight: bold;
}

/* Responsive styles for screens with max-width of 600px */
@media (max-width: 600px) {
  .inventory-main-container {
    padding: 2rem;
  }

  span.inventory-header-title {
    font-size: large;
  }

  p.inventory-paragraph-first,
  .inventory-paragraph-second,
  .inventory-paragraph-third {
    font-size: small;
  }

  img.inventory-img-class {
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  span.inventory-class-third-header-title {
    font-size: medium;
  }
}
