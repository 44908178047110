* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.purchase-main-container {
  padding: 4rem;
  flex: 1;
}

span.purchase-header-title {
  color: rgb(32, 61, 90);
  font-size: xx-large;
  font-weight: bold;
}

p.purchase-paragraph-first,
.purchase-paragraph-second{
  font-size: medium;
}

img.purchase-img-class {
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

span.purchase-class-second-header-title {
  color: rgb(32, 61, 90);
  font-size: x-large;
  font-weight: bold;
}

/* Responsive styles for screens with max-width of 600px */
@media (max-width: 600px) {
  .purchase-main-container {
    padding: 2rem;
  }

  span.purchase-header-title {
    font-size: large; 
  }

  p.purchase-paragraph-first,
  .purchase-paragraph-second {
    font-size: small;
  }

  img.purchase-img-class {
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  span.purchase-class-second-header-title {
    font-size: medium;
  }
}
