.features-main-container {
  padding: 5rem;
}

span.features-header-title {
  font-size: x-large;
  font-weight: bold;
  color: rgb(32, 61, 90);
}

hr.features-header-bottom-line {
  border-style: dashed;
}

img.feature-first-level-img-class,
.feature-second-level-img-class {
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.features-sub-container-level-first{
  display: flex;
  gap: 10rem;
}

.features-first-level-summary-left {
  display: flex;
  flex-flow: column;
  width: 50%;
}

span.features-summary-title {
  font-size: large;
  font-weight: bold;
  margin-bottom: 1rem;
}

span.features-summary-container {
  font-size: medium;
}

span.features-summary-container-subclass {
  font-size: medium;
  margin-top: 4rem;
}

.features-first-level-summary-right {
  display: flex;
  flex-flow: column;
  width: 50%;
}

.features-sub-container-level-second,
.features-sub-container-level-third,
.features-sub-container-level-fourth,
.features-sub-container-level-fifth,
.features-sub-container-level-sixth,
.features-sub-container-level-seventh,
.features-sub-container-level-eighth,
.features-sub-container-level-ninth,
.features-sub-container-level-tenth {
  display: flex;
  gap: 10rem;
  margin-top: 5rem;
}

span.features-summary-second-title {
  font-size: large;
  font-weight: bold;
  margin-bottom: 1rem;
}

span.features-summary-second-container {
  font-size: medium;
}

hr.features-bottom-line {
  margin-top: 4rem;
}

span.features-summary-second-container-subclass {
  font-size: medium;
}

/* Responsive styles for max-width 600px */
@media (max-width: 600px) {
  .features-main-container {
    padding: 2rem;
  }

  span.features-header-title {
    font-size: large;
  }

  .features-sub-container-level-first,
  .features-sub-container-level-second,
  .features-sub-container-level-third,
  .features-sub-container-level-fourth,
  .features-sub-container-level-fifth,
  .features-sub-container-level-sixth,
  .features-sub-container-level-seventh,
  .features-sub-container-level-eighth,
  .features-sub-container-level-ninth,
  .features-sub-container-level-tenth {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
  }

  .features-first-level-summary-left,
  .features-first-level-summary-right {
    width: 100%;
  }

  span.features-summary-title,
  span.features-summary-second-title {
    font-size: medium;
  }

  span.features-summary-container,
  span.features-summary-container-subclass,
  span.features-summary-second-container,
  span.features-summary-second-container-subclass {
    font-size: small;
  }

  img.feature-first-level-img-class,
  img.feature-second-level-img-class {
    width: 100%;
    max-width: 100%;
  }

  hr.features-bottom-line {
    margin-top: 2rem;
  }
}