* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.tax-main-container {
  padding: 4rem;
  flex: 1;
}

span.tax-header-title {
  color: rgb(32, 61, 90);
  font-size: xx-large;
  font-weight: bold;
}

p.tax-container-paragraph-first,
.tax-container-paragraph-second {
  font-size: medium;
}

img.tax-rate-img-class {
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

span.tax-class-second-header-title {
  color: rgb(32, 61, 90);
  font-size: x-large;
  font-weight: bold;
}

/* Responsive styles for max-width 600px */
@media (max-width: 600px) {
  .tax-main-container {
    padding: 2rem; 
  }

  span.tax-header-title {
    font-size: large; 
  }

  p.tax-container-paragraph-first,
  .tax-container-paragraph-second {
    font-size: small;
  }

  img.tax-rate-img-class {
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  span.tax-class-second-header-title {
    font-size: medium;
  }
}
